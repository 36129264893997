$(document).ready(function() {
    // Check consumer/company selection
    var customerTypeGroup = $("#bBasket .customerType");
    var companyGroup = $('#bBasket .company');

    customerTypeGroup.change(function() {
        UpdateCustomerType(true);
    });

    function UpdateCustomerType(slide) {
        customerTypeGroup.parent().removeClass('lightColor');
        var checkedRadio = customerTypeGroup.filter(":checked")
        checkedRadio.parent().addClass('lightColor');
        if (checkedRadio.val() == 'company') {
            if (slide) {
                companyGroup.slideDown();
            }
            else {
                companyGroup.show();
            }
        } else {
            if (slide) {
                companyGroup.slideUp();
            }
            else {
                companyGroup.hide();
            }
        }
    }
    UpdateCustomerType(false);

    // Check beginning selection
    var genderGroupInvoice = $("#bBasket .genderInvoice");
    var genderGroupDelivery = $("#bBasket .genderDelivery");
    genderGroupInvoice.change(function() {
        UpdateGenderGroup(genderGroupInvoice);
    });
    genderGroupDelivery.change(function() {
        UpdateGenderGroup(genderGroupDelivery);
    });
    function UpdateGenderGroup(genderGroup) {
        genderGroup.parent().removeClass('lightColor');
        var checkedRadio = genderGroup.filter(":checked")
        checkedRadio.parent().addClass('lightColor');
    }
    UpdateGenderGroup(genderGroupInvoice);
    UpdateGenderGroup(genderGroupDelivery);

    // Check beginning selection
    var optionGroupInvoice = $("#bBasket .optionInvoice");
    var optionGroupDelivery = $("#bBasket .optionDelivery");
    optionGroupInvoice.change(function() {
        UpdateGenderGroup(optionGroupInvoice);
    });
    optionGroupDelivery.change(function() {
        UpdateGenderGroup(optionGroupDelivery);
    });
    function UpdateAddressGroup(optionGroup) {
        optionGroup.parent().removeClass('lightColor');
        var checkedRadio = optionGroup.filter(":checked")
        checkedRadio.parent().addClass('lightColor');
    }
    UpdateAddressGroup(optionGroupInvoice);
    UpdateAddressGroup(optionGroupDelivery);

    // Check different delivery selection
    var differentDelivery = $("#bBasket #differentDelivery");
    var deliveryGroup = $('#bBasket .delivery');

    differentDelivery.change(function() {
        UpdateDifferentDelivery(true);
    });

    function UpdateDifferentDelivery(slide) {
        if (differentDelivery.is(':checked')) {
            if (slide) {
                deliveryGroup.slideDown();
            }
            else {
                deliveryGroup.show();
            }
        } else {
            if (slide) {
                deliveryGroup.slideUp();
            }
            else {
                deliveryGroup.hide();
            }
        }
    }
    UpdateDifferentDelivery(false);

    var paymentMethod = $('#bBasket .bPaymentMethod');
    paymentMethod.click(function() {

        paymentMethod.removeClass('active');
        paymentMethod.find('.radio').prop("checked", false);

        $(this).find('.radio').prop("checked", true);
        $(this).addClass('active');
    });

    paymentMethod.change(function() {
        paymentMethod.removeClass('active');
        $(this).addClass('active');
    });

    // Fixes older browser bug that immediatly closes the select box
    paymentMethod.find('select').click(function(e) {
        e.stopPropagation(); //kill the onclick paymentMethod event
    });

    /* Hide form, show link */
    var infoLink = $('.info.link');
    var infoForm = $('.info.form');
    infoForm.hide();
    infoLink.show();
    infoLink.click(function() {
        infoForm.show();
        infoLink.hide();
    });

    $('input[type=radio][name=shippingmethod]').change($(this).parents('form:first').submit());
});