export default function(options) {
    var defaults = {
            afterLoad: function (){}
        },
        settings = $.extend(defaults, options);

    function initFilters() {
        var filters = $('.filters'),
            list = $('.filters__list'),
            items = $('.filters__item'),
            toggle = $('.js-filter-toggle'),
            toggleSearchClear = $('.js-search-clear'),
            toggleSearchSubmit = $('.js-search-submit');

        /**
         * Hide items bases on `data-filter-items` max.
         */
        function toggleFilterView() {
            list.each(function () {
                var items = $(this).data('filter-items');
                $(this).children('li').slice(0, items).show();
            });
        }

        toggleFilterView();
        /**
         * When clicking on the show more toggle,
         * this will make sure all items in that list are shown.
         * Clicking it again will hide all items, except the first few
         * (based on the `data-filters-items` attribute).
         */
        toggle.on('click', function () {
            var list = $(this).parent().find('.filters__list'),
                toggle = $(this).parent().find('.js-filter-toggle');
            list.toggleClass('is-open');
            if (list.hasClass('is-open')) {
                toggle.html(toggle.data('less'));
                toggle.addClass('is-open');
            } else {
                toggle.html(toggle.data('more'));
                toggle.removeClass('is-open');
            }
            list.each(function () {
                var items = $(this).data('filter-items');
                $(this).children('li').slice(items).slideToggle(150);
            });
        });
        /**
         * When toggling any of the items,
         * make sure they move to the list of active filters
         */
        var activeFilters = [];
        items.each(function () {
            $(this).click(function () {
                $(this).toggleClass('selected');
            });

            $(this).find('input').on('click', function () {
                if ($(this).is(':checked')) {
                    var item = $(this).attr('name');
                    activeFilters.push(item);
                    updateActiveFilters();
                    $(this).parents('.filters__item').addClass('selected');
                } else {
                    var item = $(this).attr('name');
                    activeFilters = $.grep(activeFilters, function (value) {
                        return value != item;
                    });
                    updateActiveFilters();
                    $(this).parents('.filters__item').removeClass('selected');
                }
            });
        });
        var originalData = $('.products').html();

        function updateActiveFilters() {
            var form = $('.js-filter-form'),
                location = form.attr('action'),
                formData = form.serialize();
            if (formData != '') {
                $.get(location, formData, function (data) {
                    $('.products').html(data);
                });
            } else {
                $('.products').html(originalData);
            }
        }

        /**
         * When searching for a particular filter,
         * filter down the list based on search term
         */
        $('.js-search-filters').keyup(function () {
            var filterListItems = $(this).parents('.filters__search').siblings('.filters__list').find('.filters__item'),
                filterInputItems = $(filterListItems).find('input'),
                toggle = $(this).parents('.filters__search').siblings('.js-filter-toggle'),
                searchValue = this.value.toLowerCase(),
                valueLength = this.value.length,
                toggleSearchSubmit = $(this).siblings('.js-search-submit'),
                toggleSearchClear = $(this).siblings('.js-search-clear');
            $(toggleSearchSubmit).hide();
            $(toggleSearchClear).show();
            (valueLength == 0) ? $(toggle).show() : $(toggle).hide();
            if ($(this).val() != '') {
                $(filterListItems).each(function () {
                    var text = $.trim($(this).text()),
                        textLowerCase = text.toLowerCase();
                    if (textLowerCase.indexOf(searchValue) >= 0) {
                        $(this).show();
                    } else {
                        $(this).hide();
                    }
                });
            } else {
                $(toggleSearchSubmit).show();
                $(toggleSearchClear).hide();
                $(filterListItems).hide();
                toggleFilterView();
            }
        });
        $(toggleSearchClear).on('click', function (e) {
            e.preventDefault();
            var filterListItems = $(this).parents('.filters__search').siblings('.filters__list').find('.filters__item'),
                toggle = $(this).parents('.filters__search').siblings('.js-filter-toggle');
            $(this).parents('.filters__search').find('.js-search-filters').val('');
            $(filterListItems).hide();
            $(toggle).show();
            $(toggleSearchSubmit).show();
            $(toggleSearchClear).hide();
            toggleFilterView();
        });
    }
    initFilters();

    var filterDiv = $('.js-filter-form'),
        filterForm = filterDiv.find('form'),
        sortForm,
        overlayTimeout = 100;

    filterForm.submit(changeOverview);

    function bindArticleOverviewEvents() {
        var filterForm = filterDiv.find('form');

        filterForm.find('input[type="submit"]').hide();

        filterForm.find('input').on('blur change', function () {
            $(this).closest('.filters__item').toggleClass('selected');
            filterForm.submit();
        });

        var articlesDiv = $('.cms-shop-articles-inner');

        if (articlesDiv.css('position') == 'static') {
            articlesDiv.css('position', 'relative');
        }
        articlesDiv.prepend('<div class="articles-overlay"></div>');

        sortForm = $('.cms-articles-sorting form');

        sortForm.find('input[type="submit"]').hide();

        sortForm.find('select').change(function () {
            sortForm.submit();
        });

        sortForm.submit(changeOverview);

        $('.cms-pagination a').on('click', function () {
            event.preventDefault();
            changeOverview($(this).attr('href') + '&ajaxArticleOverview=true', true);
            return false;
        });

        $('.js-filter-form .preventDefault').on('click', function () {
            event.preventDefault();
            var checkBox = $(this).parents('.filters__label').find('input[type=checkbox]');
            checkBox.prop("checked", !checkBox.prop("checked"));
            filterForm.submit();
        });

        //Price Range
        $(".js-range-slider").slider({
            range: true,
            slide: function (event, ui) {
                $(this).parents(".rangeFilter").find(".slider-min").val(ui.values[0]);
                $(this).parents(".rangeFilter").find(".slider-max").val(ui.values[1]);
            },
            stop: function (event, ui) {
                $(this).parents("form").submit();
            },
            create: function (event, ui) {
                $(this).slider("option", "min", $(this).data("min"));
                $(this).slider("option", "max", $(this).data("max"));
                $(this).slider("values", 0, $(this).parents(".rangeFilter").find(".slider-min").val());
                $(this).slider("values", 1, $(this).parents(".rangeFilter").find(".slider-max").val());
            }
        });
        $(".slider-min").on("blur", function () {
            var parent = $(this).parents(".rangeFilter"),
                amount = parseInt($(this).val()),
                maxValue = parseInt(parent.find(".slider-max").val()),
                slider = parent.find(".ui-slider");

            if ((slider.data("min") <= amount) && (amount <= maxValue)) {
                slider.slider("values", 0, amount);
            }
            else {
                if (amount < slider.data("min")) {
                    $(this).val(slider.data("min"));
                    slider.slider("values", 0, slider.data("min"));
                }
                else {
                    $(this).val(slider.slider("values", 0));
                }
            }
            $(this).parents("form").submit();
        });
        $(".slider-max").on("blur", function () {
            var parent = $(this).parents(".rangeFilter"),
                amount = parseInt($(this).val()),
                minValue = parseInt(parent.find(".slider-min").val()),
                slider = parent.find(".ui-slider");

            if ((minValue <= amount) && (amount <= slider.data("max"))) {
                slider.slider("values", 1, amount);
            }
            else {
                if (amount > slider.data("max")) {
                    $(this).val(slider.data("max"));
                    slider.slider("values", 1, slider.data("max"));
                }
                else {
                    $(this).val(slider.slider("values", 1));
                }
            }
            $(this).parents("form").submit();
        });
    }
    bindArticleOverviewEvents();

    var request = null;
    function changeOverview(location, noform) {
        if (request !== null) {
            request.abort();
            request = null;
        }

        if (typeof(location) != "string") {
            location = $(this).attr('action');
        }

        articleOverlays(true);

        var formData = null;
        if (noform != true) {
            var form = $(this);
            form.append('<input type="hidden" name="ajaxArticleOverview" value="true" />')
            formData = form.serialize();
        }

        request = $.get(location, formData, function (data) {
            filterForm.html(data.filter);
            initFilters();

            $('.cms-shop-articles').replaceWith(data.articles);

            bindArticleOverviewEvents();

            // callback
            if (typeof(settings.afterLoad) === 'function') {
                settings.afterLoad();
            }

            articleOverlays(false);

        }, "json");

        return false;
    }

    function articleOverlays(visible) {
        if (visible) {
            $('.articles-overlay').show();
        }
        else {
            $('.articles-overlay').fadeOut(100);
        }
    }
}