window.$ = window.jQuery = require('jquery');
window.Cookies = require('js-cookie');

require('./vendor/jqueryUI');

require('./vendor/doubleTapToGo');
require('./vendor/fancybox');
require('slick-carousel');
require('./vendor/match-height');

require('./scripts/cookie');
require('./scripts/event');

import header from './scripts/header';
import merge from './scripts/merge';
import navigation from './scripts/navigation';
import setBackgroundImage from './scripts/setBackgroundImage';
import toggle from './scripts/toggle';
import googleReCaptcha from './scripts/googleRecaptcha';
import 'simplebar';

import basket from './scripts/basket';

require('./vendor/basket');
import filters from './scripts/filters';

/**
 * Main Scripts
 *
 * This main scripts file is where you'll write most
 * of your (jQuery) scripts.
 */

(function ($) {

    /**
     * As soon as this file is loaded, jQuery will remove the `no-jquery`
     * class from the `body`. This indicates that jQuery has been loaded.
     */
    $('body').removeClass('no-jquery');

    /**
     * As soon as this file is loaded, jQuery will remove the `u-jquery`
     * class from the hidden `divs`. This indicates that jQuery has been loaded.
     */
    $('.u-jquery').removeClass('u-jquery');


    /**
     * Initialize Google reCaptcha
     */
    googleReCaptcha();

    /**
     * Object with breakpoint sizes defined. These are
     * based on Bootstrap's Grid.
     *
     * @type Object
     */
    var breakpoints = {
        "xs": 480,
        "sm": 768,
        "md": 992,
        "lg": 1200
    };

    // Header
    header(breakpoints);

    $(window).resize(function () {
        header(breakpoints);
    });

    // Navigation
    navigation(breakpoints);

    /**
     * Make sure touch-enabled devices can view the drop-down menus
     * starting from a landscape mode (> breakpoints.sm)
     */
    $(window).resize(function () {
        if ($(window).outerWidth() > breakpoints.sm) {
            $('.nav .nav__item:has(ul)').doubleTapToGo();
        }
    });

    // Merge
    merge(breakpoints);

    $(window).resize(function () {
        merge(breakpoints);
    });

    // Toggle
    toggle(breakpoints);

    $(window).resize(function () {
        toggle(breakpoints);
    });

    // Smooth scroll
    $('a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);

            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

            if (target.length) {
                $('html,body').animate({
                    scrollTop: target.offset().top - 180
                }, 1000);
                return false;
            }
        }
    });

    // Replace `img` with `background-image` in parent `div`
    setBackgroundImage();

    // Webshop
    basket();

    filters({
        afterLoad: function () {
            // MatchHeight e.d.
            $('.product__item .info .table').matchHeight({
                byRow: true
            });
            $('.product__item .info h2').matchHeight({
                byRow: true
            });
            $('.product__item .info').matchHeight({
                byRow: true
            });
            $('.product__item').matchHeight({
                byRow: true
            });
            $('.content__menu .inner .info h2').matchHeight({
                byRow: true
            });
        }
    });


    // MatchHeight e.d.
    $('.product__item .info .table').matchHeight({
        byRow: true
    });
    $('.product__item .info h2').matchHeight({
        byRow: true
    });
    $('.product__item .info').matchHeight({
        byRow: true
    });
    $('.product__item').matchHeight({
        byRow: true
    });
    $('.content__menu .inner .info h2').matchHeight({
        byRow: true
    });
    $('.webshop__article .matchingcolumns').matchHeight({
        byRow: true
    });


    $('.news .item h3 a').matchHeight({
        byRow: true
    });
    $('.news .item p').matchHeight({
        byRow: true
    });
    $('.shopcatgories .image').matchHeight({
        byRow: true
    });

    // Preview product
    $('.js-gallery .inner').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
        arrows: false,
        draggable: false,
        dots: true,
        fade: true,
        asNavFor: '.js-gallery-nav',
        adaptiveHeight: true
    });

    $('.js-gallery-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        rows: 0,
        swipeToSlide: true,
        focusOnSelect: true,
        centerMode: false,
        asNavFor: '.js-gallery .inner',
        prevArrow: $('.prevArrow'),
        nextArrow: $('.nextArrow'),
        vertical: true,
        responsive: [
            {
                breakpoint: 4000,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            }, {
                breakpoint: 990,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }]
    });


    //FAQ toggle ToggleBox
    $('.faq .open-toggle').hide();
    $('.faq .open').show();
    $('.faq .closed').hide();
    $(".faq .toggle").click(function () {
        $(this).closest('.toggle-box').find(".open-toggle").slideToggle("slow", function () {
            if ($(this).closest('.toggle-box').hasClass('active')) {
                $(this).closest('.toggle-box').removeClass('active');
                $(this).closest('.toggle-box').find('.closed').hide();
                $(this).closest('.toggle-box').find('.open').show();
            }
            else {
                $(this).closest('.toggle-box').addClass('active');
                $(this).closest('.toggle-box').find('.closed').show();
                $(this).closest('.toggle-box').find('.open').hide();
            }
        });
    });

    // Fancybox
    $(".fancybox, .lightbox").fancybox({
        // Options will go here
    });

    // Slick
    $('[data-fancybox="gallery"]').fancybox({
        loop: true,
        afterShow: function (instance, slide) {
            $('.js-gallery').slick('slickGoTo', slide.index);
            $('.js-gallery-nav').slick('slickGoTo', slide.index);
        },
        buttons: [
            'slideShow',
            'fullScreen',
            'thumbs',
            // 'share',
            //'download',
            //'zoom',
            'close'
        ]
    });

    // Slider Hero
    $('.js-hero-slider').slick({
        infinite: true,
        speed: 1000,
        fade: true,
        cssEase: 'linear',
        arrows: false,
        autoplay: true,
        rows: 0,
        dots: true,
        autoplaySpeed: 4000,
        adaptiveHeight: true
    }); 

    // Categories slider
    $('.js-brandslider section').slick({
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        pauseOnHover: false,
        swipeToSlide: true,
        focusOnSelect: true,
        arrows: false,
        rows: 0,
        dots: false,
        autoplay: true,
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            }
        }, {
            breakpoint: 991,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
            }
        },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    });


    // Search toggle
    $(".header .search button").click(function (e) {
        if ($(window).outerWidth() > breakpoints.md) {
            e.preventDefault();
            $('.header .search input[type=search]').focus();
        }

        var form = $(this).parent('form');
        var status = $(this).attr('data-active');
        var textHasBeenInserted = $(this).siblings('.box').find('.keywords').val().length;

        if (status === 'false') {
            $('.header .search').toggleClass("active");
            $('.header .searchbar').toggleClass("active");

            $(this).attr('data-active', 'true');
        } else if (textHasBeenInserted === 0) {
            $('.header .search').toggleClass("active");
            $('.header .searchbar').toggleClass("active");

            $(this).attr('data-active', 'false');
        } else {
            form.submit();
        }
    });

    // Leftnav + Filters toggle
    $('.js-toggle-left-navigation').off('click').on('click', function () {
        var ToggleSubMenu = $('.shop__nav');
        var ToggleMenuButton = $('.js-toggle-left-navigation');

        if (ToggleSubMenu.hasClass('active')) {
            ToggleSubMenu.removeClass('active');
            ToggleMenuButton.removeClass('reverse active');
        } else {
            ToggleSubMenu.addClass('active');
            ToggleMenuButton.addClass('reverse active');
        }
        return false;
    });

    $('.filtertogglebutton').off('click').on('click', function () {
        var ToggleFilter = $('.filter');
        var ToggleFilterButton = $('.filtertogglebutton');

        if (ToggleFilter.hasClass('active')) {
            ToggleFilter.removeClass('active');
            ToggleFilterButton.removeClass('reverse active');
        } else {
            ToggleFilter.addClass('active');
            ToggleFilterButton.addClass('reverse active');
        }
        return false;
    });

    //scroll at product detail
    $.fn.matchHeight._afterUpdate = function (event, groups) {
        // Sidebar scroll

        if ($('.accessories').length)  {
            var articleScroll = $('.webshop__article .article__scroll');
            var articleScrollHeight = $(articleScroll).outerHeight();

            var footerFromTop = ($('.accessories').offset().top - 0);
            var articleScrollAbsolutePosition = ((footerFromTop - articleScrollHeight) - articleScrollHeight);

            $(articleScroll).outerWidth($(articleScroll).parents('.webshop__article .prod__info').width());

            $(window).scroll(function () {
                if ($(window).scrollTop() > articleScrollAbsolutePosition) {
                    $(articleScroll).addClass('absolute');
                } else {
                    $(articleScroll).removeClass('absolute');
                }
            });
        }
    };

    // Info Message fadeout
    setTimeout(function () {
        $('.info.message').fadeOut('fast');
    }, 4000);

    // Product Anchors
    $(".js-anchors .anchor__item .anchor__link").click(function () {
        $(this).parent().addClass('selected');
        $(this).parent().siblings().removeClass('selected');
    });

    // This button will increment the value
    $('.qtyplus').click(function (e) {
        // Stop acting like a button
        e.preventDefault();
        // Get the field name
        var field = $(this).parent().find('input.amount');
        // Get its current value
        var currentVal = parseInt(field.val());
        // If is not undefined
        if (!isNaN(currentVal)) {
            // Increment
            field.val(currentVal + 1);
        } else {
            // Otherwise put a 1 there
            field.val(1);
        }
    });

    // This button will decrement the value till 1
    $(".qtyminus").click(function (e) {
        // Stop acting like a button
        e.preventDefault();
        // Get the field name
        var field = $(this).parent().find('input.amount');
        // Get its current value
        var currentVal = parseInt(field.val());
        // If it isn't undefined or its greater than 1
        if (!isNaN(currentVal) && currentVal > 1) {
            // Decrement one
            field.val(currentVal - 1);
        } else {
            // Otherwise put a 1 there
            field.val(1);
        }
    });

})(jQuery);
