export default function () {
    $('[data-set-background-image]').each(function (index) {
        if ($(this).find('img').length) {
            var image = $(this).find('img');
            var imageUrl = $(image).attr('src');

            $(this).find('*').hide();

            $(this).css({
                'background-image': 'url(' + imageUrl + ')'
            });
        }
    });
}
